<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Mentions légales</v-card-title>
        <v-card-subtitle>Retrouvez l'ensemble des informations de notre application ici.</v-card-subtitle>
        <v-card-text>
            <v-btn block color="secondary" class="mb-4">Lire les mentions légales</v-btn>
            <v-btn block color="primary" class="mb-4">Télécharger les mentions légales</v-btn>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Mentions',
}
</script>